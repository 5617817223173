var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"teeth_view",class:{teeth_view_old: _vm.isOldCase}},[_c('ul',{staticClass:"view_head"},[_c('li',{staticClass:"head_single"},[_c('div',{staticClass:"h_single_left"},[_c('div',{staticClass:"h_s_l_icon01",class:{
          'admin': 'blue_yachi_y',
          'clinic': 'blue_yachi_y',
          'irdt': 'purple_yachi_y',
        }[_vm.queryPort] || 'yellow_yachi_y'}),_vm._m(0)]),_c('div',{staticClass:"h_single_right"},[_c('p',{staticClass:"r_strong_p",class:{main_theme_color_333: _vm.isOldCase}},[_vm._v(_vm._s(_vm.$t('common.component.lyqyl')))]),_c('p',{staticClass:"r_strong_p",class:{main_theme_color_333: _vm.isOldCase}},[_vm._v(_vm._s(_vm.$t('common.component.zlq')))])])]),_c('li',{staticClass:"head_single"},[_c('div',{staticClass:"h_single_left"},[_c('div',{staticClass:"h_s_l_icon",class:{
          'admin': 'blue_h_s_l_icon_fj',
          'clinic': 'blue_h_s_l_icon_fj',
          'irdt': 'purple_h_s_l_icon_fj',
        }[_vm.queryPort] || 'h_s_l_icon_fj'})]),_c('div',{staticClass:"h_single_right"},[_c('p',{staticClass:"r_strong_p",class:{main_theme_color_333: _vm.isOldCase}},[_vm._v(_vm._s(_vm.$t('common.component.fj')))])])]),_c('li',{staticClass:"head_single"},[_c('div',{staticClass:"h_single_left"},[_c('div',{staticClass:"h_s_l_icon",class:{
          'admin': 'blue_h_s_l_icon_qs',
          'clinic': 'blue_h_s_l_icon_qs',
          'irdt': 'purple_h_s_l_icon_qs',
        }[_vm.queryPort] || 'h_s_l_icon_qs'})]),_c('div',{staticClass:"h_single_right"},[_c('p',{staticClass:"r_strong_p",class:{main_theme_color_333: _vm.isOldCase}},[_vm._v(_vm._s(_vm.$t('common.component.qsy')))])])])]),_c('div',{staticClass:"teeth_box"},[(_vm.showTopTeeth)?_c('ul',{staticClass:"teeth___top teeth_t_strand"},_vm._l(([55, 54, 53, 52, 51, 61, 62, 63, 64, 65]),function(item,index){return _c('li',{key:(item + "_" + index),staticClass:"teeth___single"},[_c('div',{staticClass:"s_pic_box"},[_c('div',{staticClass:"t__s_pic",class:_vm.babyTeethTopFn({babyTeethList: _vm.babyTeethList,item: item})},_vm._l((_vm.teethKeyObj[item]),function(it,_in){return _c('span',{key:it,staticClass:"t__s_tag",class:_vm.teethAccessory({item: item},_in)})}),0)]),_c('p',{staticClass:"t__s_p",class:{main_theme_color_333: _vm.isOldCase}},[_vm._v(_vm._s(item))]),(_vm.cutInfoObj[item === 51 ? 61 : item > 60 ? item + 1 : item -1])?_c('div',{staticClass:"t_jx_tag"},[_c('div',{staticClass:"top_deciduous_baby"},[_c('div',{staticClass:"t_jx_num"},[_vm._l((_vm.cutInfoObj[item === 51 ? 61 : item > 60 ? item + 1 : item -1]),function(it){return [_c('p',[_vm._v(_vm._s(_vm.get(it,'width'))+"mm")]),(_vm.get(it,'beforeStep'))?_c('p',[_vm._v(_vm._s(_vm.get(it,'beforeStep'))+"<")]):_vm._e()]})],2)]),_c('p',{staticClass:"t_jx_tag_b"})]):_vm._e()])}),0):_vm._e(),_c('ul',{staticClass:"teeth___top"},_vm._l(([18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28]),function(item,index){return _c('li',{key:(item + "_" + index),staticClass:"teeth___single"},[_c('div',{staticClass:"s_pic_box"},[_c('div',{staticClass:"t__s_pic",class:(({
              'admin': 'blue_',
              'clinic': 'blue_',
              'irdt': 'purple_'
            }[_vm.queryPort] || '') + "t__s_pic" + item)},_vm._l((_vm.teethKeyObj[item]),function(it,_in){return _c('span',{key:it,staticClass:"t__s_tag",class:_vm.teethAccessory({item: item},_in)})}),0)]),_c('p',{staticClass:"t__s_p",class:{main_theme_color_333: _vm.isOldCase}},[_vm._v(_vm._s(item))]),(_vm.cutInfoObj[item === 11 ? 21 : item > 20 ? item + 1 : item - 1])?_c('div',{staticClass:"t_jx_tag",attrs:{"value":1}},[_c('div',{class:_vm.some(Object.keys(_vm.babyTeethKeyObj),function (items){ return items>50; }) ? _vm.some(Object.keys(_vm.babyTeethKeyObj), function (itemss) { return itemss-item === 40; }) || index % 2 === 0 ? 't_jx_tag_t_top' :'t_jx_tag_t_top_height' : 't_jx_tag_t'},[_c('div',{staticClass:"t_jx_num"},[_vm._l((_vm.cutInfoObj[item === 11 ? 21 : item > 20 ? item + 1 : item -1].reverse()),function(it){return [_c('p',[_vm._v(_vm._s(_vm.get(it,'width'))+"mm")]),(_vm.get(it,'beforeStep'))?_c('p',[_vm._v(_vm._s(_vm.get(it,'beforeStep'))+"<")]):_vm._e()]})],2)]),_c('p',{staticClass:"t_jx_tag_b"})]):_vm._e()])}),0),_c('div',{staticClass:"teeth__line_box"},[_vm._v(" "+_vm._s(_vm.$t('common.component.y'))+" "),_c('p',{staticClass:"teeth__line"}),_vm._v(" "+_vm._s(_vm.$t('common.component.z'))+" ")]),_c('ul',{staticClass:"teeth___bottom"},_vm._l(([48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38]),function(item,index){return _c('li',{key:(item + "_" + index),staticClass:"teeth___single"},[_c('p',{staticClass:"t__s_p",class:{main_theme_color_333: _vm.isOldCase}},[_vm._v(_vm._s(item))]),_c('div',{staticClass:"s_pic_box"},[_c('div',{staticClass:"t__s_pic",class:(({
              'admin': 'blue_',
              'clinic': 'blue_',
              'irdt': 'purple_'
            }[_vm.queryPort] || '') + "t__s_pic" + item)},_vm._l((_vm.teethKeyObj[item]),function(it,_in){return _c('span',{key:it,staticClass:"t__s_tag",class:_vm.teethAccessory({item: item},_in)})}),0)]),(_vm.cutInfoObj[item === 41 ? 31 : (item > 30 && item < 41) ? item + 1 : item - 1])?_c('div',{staticClass:"t_jx_tag"},[_c('p',{staticClass:"t_jx_tag_b"}),_c('div',{class:_vm.some(Object.keys(_vm.babyTeethKeyObj),function (items){ return items > 70; }) ?
          _vm.some(Object.keys(_vm.babyTeethKeyObj), function (itemss) { return itemss-item === 40; }) || index % 2 === 0 ? 't_jx_tag_t_top' :'t_jx_tag_t_top_height' : 't_jx_tag_t'},[_c('div',{staticClass:"t_jx_num"},[_vm._l((_vm.cutInfoObj[item === 41 ? 31 :(item > 30 && item < 41) ? item + 1 : item -1]),function(it){return [_c('p',[_vm._v(_vm._s(_vm.get(it,'width'))+"mm")]),(_vm.get(it,'beforeStep'))?_c('p',[_vm._v(_vm._s(_vm.get(it,'beforeStep'))+"<")]):_vm._e()]})],2)])]):_vm._e()])}),0),(_vm.showBtmTeeth)?_c('ul',{staticClass:"teeth___bottom teeth_b_strand"},_vm._l(([85, 84, 83, 82, 81, 71, 72, 73, 74, 75]),function(item,index){return _c('li',{key:(item + "_" + index),staticClass:"teeth___single"},[_c('p',{staticClass:"t__s_p",class:{main_theme_color_333: _vm.isOldCase}},[_vm._v(_vm._s(item))]),_c('div',{staticClass:"s_pic_box"},[_c('div',{staticClass:"t__s_pic",class:_vm.bottomBabyTeeth({item: item,babyTeethList: _vm.babyTeethList})},_vm._l((_vm.teethKeyObj[item]),function(it,_in){return _c('span',{key:it,staticClass:"t__s_tag",class:_vm.teethAccessory({item: item},_in)})}),0)]),(_vm.cutInfoObj[item === 81 ? 71 : (item > 70 && item < 81) ? item + 1 : item - 1])?_c('div',{staticClass:"t_jx_tag"},[_c('p',{staticClass:"t_jx_tag_b"}),_c('div',{staticClass:"top_deciduous_baby"},[_c('div',{staticClass:"t_jx_num"},[_vm._l((_vm.cutInfoObj[item === 81 ? 71 : (item > 70 && item < 81) ? item + 1 : item - 1]),function(it){return [_c('p',[_vm._v(_vm._s(_vm.get(it,'width'))+"mm")]),(_vm.get(it,'beforeStep'))?_c('p',[_vm._v(_vm._s(_vm.get(it,'beforeStep'))+"<")]):_vm._e()]})],2)])]):_vm._e()])}),0):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h_s_r_txt"},[_c('p',{staticClass:"h_s_r_txt_p"},[_vm._v("XX.mm")]),_c('p',[_vm._v("<y")])])}]

export { render, staticRenderFns }