<template>
  <div :class="{teeth_view_old: isOldCase}" class="teeth_view">
    <ul class="view_head">
      <li class="head_single">
        <div class="h_single_left">
          <div class="h_s_l_icon01" :class="{
            'admin': 'blue_yachi_y',
            'clinic': 'blue_yachi_y',
            'irdt': 'purple_yachi_y',
          }[queryPort] || 'yellow_yachi_y'"></div>
          <div class="h_s_r_txt">
            <p class="h_s_r_txt_p">XX.mm</p>
            <p>&lt;y</p>
          </div>
        </div>
        <div class="h_single_right">
          <p :class="{main_theme_color_333: isOldCase}" class="r_strong_p">{{$t('common.component.lyqyl')}}</p>
          <p :class="{main_theme_color_333: isOldCase}" class="r_strong_p">{{$t('common.component.zlq')}}</p>
        </div>
      </li>
      <li class="head_single">
        <div class="h_single_left">
          <div class="h_s_l_icon" :class="{
            'admin': 'blue_h_s_l_icon_fj',
            'clinic': 'blue_h_s_l_icon_fj',
            'irdt': 'purple_h_s_l_icon_fj',
          }[queryPort] || 'h_s_l_icon_fj'"></div>
        </div>
        <div class="h_single_right">
          <p :class="{main_theme_color_333: isOldCase}" class="r_strong_p">{{$t('common.component.fj')}}</p>
        </div>
      </li>
    </ul>
    <div class="teeth_box">
      <ul class="teeth___top teeth_t_strand" v-if="showTopTeeth">
        <li class="teeth___single"
            v-for="(item, index) in [55, 54, 53, 52, 51, 61, 62, 63, 64, 65]"
            :key="index">
          <div class="s_pic_box">
            <div class="t__s_pic"
                 :class="topBabyTeeth(item)">
              <!--              <div class="fj-tip">
                              <div v-if="teethKeyObj[item]" class="fj-tip-line fj-tip-line-top"></div>
                              <span :style="{bottom: `${1.7 + _in*0.15}rem`}"  :key="_in" v-for="(it,_in) in teethKeyObj[item]" class="fj-tip-step">
                                {{it.stepStart}}&#45;&#45;{{it.stepEnd}}
                              </span>
                            </div>-->
              <span
                  v-for="(it,_in) in teethKeyObj[item]"
                  class="t__s_tag"
                  :key="it"
                  :class="teethAccessory({item},_in)"
              >
              </span>
            </div>
          </div>
          <p :class="{main_theme_color_333: isOldCase}" class="t__s_p">{{item}}</p>
          <div class="t_jx_tag" v-if="cutInfoObj[item === 51 ? 61 : item > 60 ? item + 1 : item -1]">
            <div class="top_deciduous_baby">
              <div class="t_jx_num">
                <template v-for="it in  cutInfoObj[item === 51 ? 61 : item > 60 ? item + 1 : item -1].reverse()">
                  <p>{{get(it,'width')}}mm</p>
                  <p v-if="get(it,'beforeStep')">{{get(it,'beforeStep')}}&lt;</p>
                </template>
                <!--                <p>{{cutInfoObj[item === 51 ? 61 : item > 60 ? item + 1 : item -1]['width']}}mm</p>-->
                <!--                <p v-if="cutInfoObj[item === 51 ? 61 : item > 60 ? item + 1 : item -1]['beforeStep']">&lt;{{cutInfoObj[item === 51 ? 61 : item > 60 ? item + 1 : item -1]['beforeStep']}}</p>-->
              </div>
            </div>
            <p class="t_jx_tag_b"></p>
          </div>
        </li>
      </ul>
      <ul v-if="patientType === 'youth'" class="teeth___top">
        <template v-for="(item, index) in [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28]">
          <li class="teeth___single" :key="index">
            <div class="s_pic_box">
              <div class="t__s_pic" :class="teethPic({pitchTeethList,item})">
                <!--                <div class="fj-tip">
                                  <div v-if="teethKeyObj[item]" class="fj-tip-line fj-tip-line-top"></div>
                                  <span
                                      :style="{bottom: `${1.7 + _in*0.15}rem`,left:`${!_in?'56%':'50%'}`}"
                                      :key="_in"
                                        v-for="(it,_in) in teethKeyObj[item]" class="fj-tip-step">
                                  {{it.stepStart}}&#45;&#45;{{it.stepEnd}}
                                </span>
                                </div>-->
                <span
                    v-for="(it,_in) in teethKeyObj[item]"
                    class="t__s_tag"
                    :key="it"
                    :class="teethAccessory({item},_in)"></span>
              </div>
            </div>
            <p :class="{main_theme_color_333: isOldCase}" class="t__s_p">{{item}}</p>
            <div :value="1" class="t_jx_tag" v-if="cutInfoObj[item === 11 ? 21 : item > 20 ? item + 1 : item - 1]">
              <div :class="some(map(teethList, it => it.number),items=>items>50) ?
              some(map(teethList, it => it.number), itemss => itemss-item === 40) || index % 2 === 0 ?
              't_jx_tag_t_top' :'t_jx_tag_t_top_height' : 't_jx_tag_t'">
                <div class="t_jx_num">
                  <template v-for="it in  cutInfoObj[item === 11 ? 21 : item > 20 ? item + 1 : item - 1].reverse()">
                    <p>{{get(it,'width')}}mm</p>
                    <p v-if="get(it,'beforeStep')">{{get(it,'beforeStep')}}&lt;</p>
                  </template>
                  <!--                  <p>{{get(cutInfoObj[item === 11 ? 21 : item > 20 ? item + 1 : item -1],'width')}}mm</p>-->
                  <!--                  <p v-if="get(cutInfoObj[item === 11 ? 21 : item > 20 ? item + 1 : item -1],'beforeStep')">&lt;{{get(cutInfoObj[item === 11 ? 21 : item > 20 ? item + 1 : item -1],'beforeStep')}}</p>-->
                </div>
              </div>
              <p class="t_jx_tag_b"></p>
            </div>
          </li>
        </template>
      </ul>
      <div class="teeth__line_box">
        {{$t('common.component.y')}} <p class="teeth__line"></p> {{$t('common.component.z')}}
      </div>
      <ul v-if="patientType === 'youth'" class="teeth___bottom">
        <li
            class="teeth___single"
            v-for="(item, index) in [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38]"
            :key="`${item}_${index}`">
          <p :class="{main_theme_color_333: isOldCase}" class="t__s_p">{{item}}</p>
          <div class="s_pic_box">
            <div class="t__s_pic" :class="teethPic({item,pitchTeethList})">
              <!--              <div class="fj-tip">
                              <div v-if="teethKeyObj[item]" class="fj-tip-line fj-tip-line-top"></div>
                              <span :style="{bottom: `${1.7 + _in*0.15}rem`}"  :key="_in" v-for="(it,_in) in teethKeyObj[item]" class="fj-tip-step">
                                {{it.stepStart}}&#45;&#45;{{it.stepEnd}}
                              </span>
                            </div>-->
              <span
                  v-for="(it,_in) in teethKeyObj[item]"
                  :key="it"
                  class="t__s_tag"
                  :class="teethAccessory({item},_in)"
              ></span>
            </div>
          </div>
          <div class="t_jx_tag" v-if="cutInfoObj[item === 41 ? 31 : (item > 30 && item < 41) ? item + 1 : item - 1]">
            <p class="t_jx_tag_b"></p>
            <div :class="some(map(teethList, it => it.number),items=> items > 70) ?
            some(map(teethList, it => it.number), itemss => itemss-item === 40) || index % 2 === 0 ?
            't_jx_tag_t_top' :'t_jx_tag_t_top_height' : 't_jx_tag_t'">
              <div class="t_jx_num">
                <template v-for="it in  cutInfoObj[item === 41 ? 31 :(item > 30 && item < 41) ? item + 1 : item -1]">
                  <p>{{get(it,'width')}}mm</p>
                  <p v-if="get(it,'beforeStep')">{{get(it,'beforeStep')}}&lt;</p>
                </template>
                <!--                  <p>{{get(cutInfoObj[item === 41 ? 31 :(item > 30 && item < 41) ? item + 1 : item -1],'width')}}mm</p>-->

                <!--                  <p v-if="get(cutInfoObj[(item > 30 && item < 41) ? item + 1 : item - 1],'beforeStep')">{{get(cutInfoObj[(item > 30 && item < 41) ? item + 1 : item - 1],'beforeStep')}}&lt;</p>-->

              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="teeth___bottom teeth_b_strand" v-if="showBtmTeeth">
        <li
            class="teeth___single"
            v-for="(item, index) in [85, 84, 83, 82, 81, 71, 72, 73, 74, 75]"
            :key="index">
          <p :class="{main_theme_color_333: isOldCase}" class="t__s_p">{{item}}</p>
          <div class="s_pic_box">
            <div class="t__s_pic" :class="bottomBabyTeeth(item)">
              <!--              <div class="fj-tip">
                              <div v-if="teethKeyObj[item]" class="fj-tip-line fj-tip-line-bottom"></div>
                              <span :style="{top: `${1.7 + _in*0.15}rem`}"  :key="_in" v-for="(it,_in) in teethKeyObj[item]" class="fj-tip-step">
                                {{it.stepStart}}&#45;&#45;{{it.stepEnd}}
                              </span>
                            </div>-->
              <span
                  v-for="(it,_in) in teethKeyObj[item]"
                  :key="it"
                  class="t__s_tag"
                  :class="teethAccessory({item},_in)"
              ></span>
            </div>
          </div>
          <div class="t_jx_tag" v-if="cutInfoObj[item === 81 ? 71 : (item > 70 && item < 81) ? item + 1 : item - 1]">
            <p class="t_jx_tag_b"></p>
            <div class="top_deciduous_baby">
              <div class="t_jx_num">
                <template v-for="it in  cutInfoObj[item === 81 ? 71 : (item > 70 && item < 81) ? item + 1 : item - 1]">
                  <p>{{get(it,'width')}}mm</p>
                  <p v-if="get(it,'beforeStep')">{{get(it,'beforeStep')}}&lt;</p>
                </template>
                <!--                <p>{{get(cutInfoObj[(item > 70 && item < 81) ? item + 1 : item - 1],'width')}}mm</p>-->
                <!--                <p v-if="get(cutInfoObj[(item > 70 && item < 81) ? item + 1 : item - 1],'beforeStep')">{{get(cutInfoObj[(item > 70 && item < 81) ? item + 1 : item - 1],'beforeStep')}}&lt;</p>-->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { map,some,each,get } from "lodash";
export default {
  data() {
    return {
      teethKeyObj: {},
      cutInfoObj: {},

      showTopTeeth: false,
      showBtmTeeth: false,
      babyTeethList:[],

      pitchTeethList:[],

    }
  },
  computed:{
    schemeType(){
      return this.$route.query.schemeType || 'target';
    },
    queryPort(){
      return '';
    }
  },
  props: {
    teethList: {
      type: Array,
      required: true
    },
    cutInfoList: {
      type: Array,
      required: true
    },
    patientType:{
      type: String,
      default: 'youth'
    },
    isOldCase:{
      type: Boolean,
      default: false
    }
  },
  methods:{
    some,
    each,
    get,
    map,
    topBabyTeeth(item){
      if(this.pitchTeethList.includes(item)){
        if([55,54].includes(item)){
          return {
            'admin': 'blue_t__s_pic_top_left',
            'clinic': 'blue_t__s_pic_top_left',
            'irdt': 'purple_t__s_pic_top_left',
          }[this.queryPort] || 't__s_pic_top_left'
        }else if([65,64].includes(item)){
          return {
            'admin': 'blue_t__s_pic_top_right',
            'clinic': 'blue_t__s_pic_top_right',
            'irdt': 'purple_t__s_pic_top_right',
          }[this.queryPort] || 't__s_pic_top_right'
        }
      }else{
        if(![65,64,55,54].includes(item)){
          return `t__s_pic${item - 40}_nonentity`
        }else if([55,54].includes(item)){
          return 't__s_pic54_nonentity'
        }else if([65,64].includes(item)){
          return 't__s_pic64_nonentity'
        }
      }
      return {
        'admin': `blue_t__s_pic${item - 40}`,
        'clinic': `blue_t__s_pic${item - 40}`,
        'irdt': `purple_t__s_pic${item - 40}`,
      }[this.queryPort] || `t__s_pic${item - 40}`
    },
    bottomBabyTeeth(item){
      if(this.pitchTeethList.includes(item)){
        if([85,84].includes(item)){
          return {
            'admin': 'blue_t__s_pic_bottom_left',
            'clinic': 'blue_t__s_pic_bottom_left',
            'irdt': 'purple_t__s_pic_bottom_left',
          }[this.queryPort] || 't__s_pic_bottom_left'
        }else if([75,74].includes(item)){
          return {
            'admin': 'blue_t__s_pic_bottom_right',
            'clinic': 'blue_t__s_pic_bottom_right',
            'irdt': 'purple_t__s_pic_bottom_right',
          }[this.queryPort] || 't__s_pic_bottom_right'
        }
      }else{
        if(![85,84,75,74].includes(item)){
          return `t__s_pic${item - 40}_nonentity`
        }else if([85,84].includes(item)){
          return 't__s_pic47_nonentity'
        }else if([75,74].includes(item)){
          return 't__s_pic37_nonentity'
        }
      }
      return {
        'admin': `blue_t__s_pic${item - 40}`,
        'clinic': `blue_t__s_pic${item - 40}`,
        'irdt': `purple_t__s_pic${item - 40}`,
      }[this.queryPort] || `t__s_pic${item - 40}`
    },
    teethPic({pitchTeethList,item}){
      return `${
          pitchTeethList.includes(item) && this.queryPort ? {
            'admin': 'blue_',
            'clinic': 'blue_',
            'irdt': 'purple_',
          }[this.queryPort]  :  ''
      }t__s_pic${item}${pitchTeethList.includes(item)? '' : '_nonentity'}`
    },
    teethAccessory({item},_in){
      if(this.teethKeyObj[item][_in]){
        return  `${
            {
              'admin': 'blue_',
              'clinic': 'blue_',
              'irdt': 'purple_',
            }[this.queryPort] || ''
        }${this.teethKeyObj[item][_in]['className']}`
      }
    }
  },
  created(){
    //2 teeth_no_move  3 teeth_no_design
    each(this.teethList, item => {
      if(['1', '4'].includes(item.type)) {
        this.teethKeyObj[item.number] = {
          type: '1',
          number: item.number,
          className: 'teeth_missing'
        };
      }else if (item.type === '6') {

        if (this.teethKeyObj[item.number]?.length){
          this.teethKeyObj[item.number].push({
            type: '6',
            number: item.number,
            className: 't__s_tag_fj',
            stepStart:item.stepStart,
            stepEnd:item.stepEnd,
          })
        }else {
          this.teethKeyObj[item.number] = [{
            type: '6',
            number: item.number,
            className: 't__s_tag_fj',
            stepStart:item.stepStart,
            stepEnd:item.stepEnd,
          }];
        }
        // this.teethKeyObj[item.number] = {
        //   type: '6',
        //   number: item.number,
        //   className: 't__s_tag_fj'
        // };
      }else if (item.type === '8') {
        this.pitchTeethList.push(item.number)
      }
    });
    each(this.cutInfoList, item => {
      if (this.cutInfoObj[+item.toothEnd]){
        this.cutInfoObj[+item.toothEnd].push(item)
      }else{
        this.cutInfoObj[+item.toothEnd]=[item]
      }
    });
    // each(this.cutInfoList, item => {
    //   this.cutInfoObj[+item.toothEnd] = {
    //     ...item
    //   }
    // });
    console.log('cutInfoObj',this.cutInfoObj)
    console.log('teethKeyObj',this.teethKeyObj)
    this.showTopTeeth = some(this.teethList, item => [55,54,53,52,51,61,62,63,64,65].includes(item.number) && item.type === '8');
    this.showBtmTeeth = some(this.teethList, item => [85,84,83,82,81,71,72,73,74,75].includes(item.number) && item.type === '8');
  },
}
</script>

<style scoped lang="scss">
.teeth_view {
  background-color: #58595A;
  padding: 0.39rem 0.21rem 1.6rem;
  border-radius: 4px;
  .view_head {
    display: flex;
    padding-right: 0.2rem;
    justify-content: flex-end;
    margin-bottom: 1.7rem;
    .head_single {
      display: flex;
      margin-left: 0.8rem;
      .h_single_left {
        display: flex;
        margin-right: 0.12rem;
        .h_s_l_icon {
          width: 0.14rem;
          height: 0.14rem;
          background-size: 100% 100%;
        }

        .h_s_l_icon_fj {
          background-image: url("/static/images/teeth_fj_tag.png");
        }
        .blue_h_s_l_icon_fj{
          background-image: url("../../../common/imgs/3d/blue_teeth_fj_tag.png");
        }
        .purple_h_s_l_icon_fj{
          background-image: url("../../../common/imgs/3d/purple_teeth_fj_tag.png");
        }


        .h_s_l_icon_qs {
          background-image: url("/static/images/teeth_qs_tag.png");
        }
        .h_s_l_icon01 {
          margin-right: 0.1rem;
          width: 0.5rem;
          height: 0.39rem;
          background-size: 100% 100%;
        }
        .yellow_yachi_y{
          background-image: url("/static/images/yachi_y.png");
        }
        .blue_yachi_y{
          background-image: url("../../../common/imgs/3d/blue_yachi_y.png");
        }
        .purple_yachi_y{
          background-image: url("../../../common/imgs/3d/purple_yachi_y.png");
        }

        .h_s_r_txt {
          color: #FCC80E;
          .h_s_r_txt_p {
            margin-bottom: 0.1rem;
          }
        }
      }
      .h_single_right {
        .r_strong_p {
          margin-bottom: 0.1rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .teeth_box {
    margin-top: 250px;
    margin-bottom: 50px;
    .teeth___top, .teeth___bottom {
      display: flex;
      padding: 0 0.4rem;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      .teeth___single {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        &:nth-of-type(odd) .t_jx_tag_t{
          height: 1rem;
        }
        &:nth-of-type(even) .t_jx_tag_t{
          height: 0.4rem;
        }
        .s_pic_box {
          height: 0.56rem;
          display: flex;
          flex-direction: column;
          position: relative;
        }
        .t__s_pic {
          background-size: 100% 100%;
          position: relative;
          .t__s_tag {
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0.16rem;
            height: 0.16rem;
            background-size: 100% 100%;
          }
          .teeth_missing{
            background-image: url("/static/images/teeth_qs_tag.png") !important;
          }
          .t__s_tag_fj {
            background-image: url("/static/images/teeth_fj_tag.png");
          }
          .blue_t__s_tag_fj{
            background-image: url("../../../common/imgs/3d/blue_teeth_fj_tag.png");
          }
          .purple_t__s_tag_fj{
            background-image: url("../../../common/imgs/3d/purple_teeth_fj_tag.png");
          }
        }
        .t__s_pic18 {
          width: 0.42rem;
          height: 0.32rem;
          background-image: url("/static/images/teeth/web_doc_tooth18.png");
        }
        .blue_t__s_pic18{
          width: 0.42rem;
          height: 0.32rem;
          background-image: url("./img/blue_web_doc_tooth18.png");
        }
        .purple_t__s_pic18{
          width: 0.42rem;
          height: 0.32rem;
          background-image: url("./img/purple_web_doc_tooth18.png");
        }
        .t__s_pic18_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth18_nonentity.png");
        }
        .t__s_pic17 {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth17.png");
        }
        .blue_t__s_pic17{
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("./img/blue_web_doc_tooth17.png");
        }
        .purple_t__s_pic17{
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("./img/purple_web_doc_tooth17.png");
        }
        .t__s_pic17_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth17_nonentity.png");
        }
        .t__s_pic16 {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth16.png");
        }
        .blue_t__s_pic16{
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("./img/blue_web_doc_tooth16.png");
        }
        .purple_t__s_pic16{
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("./img/purple_web_doc_tooth16.png");
        }
        .t__s_pic16_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth16_nonentity.png");
        }
        .t__s_pic15 {
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/web_doc_tooth15.png");
        }
        .blue_t__s_pic15{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("./img/blue_web_doc_tooth15.png");
        }
        .purple_t__s_pic15{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("./img/purple_web_doc_tooth15.png");
        }
        .t__s_pic15_nonentity{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/web_doc_tooth15_nonentity.png");
        }

        .t__s_pic_top_left {
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/web_doc_tooth17.png");
        }
        .blue_t__s_pic_top_left{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("./img/blue_web_doc_tooth17.png");
        }
        .purple_t__s_pic_top_left{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("./img/purple_web_doc_tooth17.png");
        }

        .t__s_pic_top_right {
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/web_doc_tooth27.png");
        }
        .blue_t__s_pic_top_right{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("./img/blue_web_doc_tooth27.png");
        }
        .purple_t__s_pic_top_right{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("./img/purple_web_doc_tooth27.png");
        }


        .t__s_pic_bottom_left{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/web_doc_tooth47.png");
        }
        .blue_t__s_pic_bottom_left{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("./img/blue_web_doc_tooth47.png");
        }
        .purple_t__s_pic_bottom_left{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("./img/purple_web_doc_tooth47.png");
        }

        .t__s_pic_bottom_right{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/web_doc_tooth37.png");
        }
        .blue_t__s_pic_bottom_right{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("./img/blue_web_doc_tooth37.png");
        }
        .purple_t__s_pic_bottom_right{
          width: 0.5rem;
          height: 0.4rem;
          background-image: url("./img/purple_web_doc_tooth37.png");
        }


        .t__s_pic14 {
          width: 0.34rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/web_doc_tooth14.png");
        }
        .blue_t__s_pic14{
          width: 0.34rem;
          height: 0.44rem;
          background-image: url("./img/blue_web_doc_tooth14.png");
        }
        .purple_t__s_pic14{
          width: 0.34rem;
          height: 0.44rem;
          background-image: url("./img/purple_web_doc_tooth14.png");
        }
        .t__s_pic14_nonentity{
          width: 0.34rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/54.png");
        }
        .t__s_pic13 {
          width: 0.42rem;
          height: 0.6rem;
          background-image: url("/static/images/teeth/web_doc_tooth13.png");
        }
        .blue_t__s_pic13{
          width: 0.42rem;
          height: 0.6rem;
          background-image: url("./img/blue_web_doc_tooth13.png");
        }
        .purple_t__s_pic13{
          width: 0.42rem;
          height: 0.6rem;
          background-image: url("./img/purple_web_doc_tooth13.png");
        }
        .t__s_pic13_nonentity{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/53.png");
        }

        .t__s_pic12 {
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("/static/images/teeth/web_doc_tooth12.png");
        }
        .blue_t__s_pic12{
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("./img/blue_web_doc_tooth12.png");
        }
        .purple_t__s_pic12{
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("./img/purple_web_doc_tooth12.png");
        }
        .t__s_pic12_nonentity{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/52.png");
        }

        .t__s_pic11 {
          width: 0.43rem;
          height: 0.56rem;
          background-image: url("/static/images/teeth/web_doc_tooth11.png");
        }
        .blue_t__s_pic11{
          width: 0.43rem;
          height: 0.56rem;
          background-image: url("./img/blue_web_doc_tooth11.png");
        }
        .purple_t__s_pic11{
          width: 0.43rem;
          height: 0.56rem;
          background-image: url("./img/purple_web_doc_tooth11.png");
        }
        .t__s_pic11_nonentity{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/51.png");
        }

        .t__s_pic28 {
          width: 0.42rem;
          height: 0.32rem;
          background-image: url("/static/images/teeth/web_doc_tooth28.png");
        }
        .blue_t__s_pic28{
          width: 0.42rem;
          height: 0.32rem;
          background-image: url("./img/blue_web_doc_tooth28.png");
        }
        .purple_t__s_pic28{
          width: 0.42rem;
          height: 0.32rem;
          background-image: url("./img/purple_web_doc_tooth28.png");
        }
        .t__s_pic28_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth28_nonentity.png");
        }
        .t__s_pic27 {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth27.png");
        }
        .blue_t__s_pic27{
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("./img/blue_web_doc_tooth27.png");
        }
        .purple_t__s_pic27{
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("./img/purple_web_doc_tooth27.png");
        }
        .t__s_pic27_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth27_nonentity.png");
        }
        .t__s_pic26 {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth26.png");
        }
        .blue_t__s_pic26{
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("./img/blue_web_doc_tooth26.png");
        }
        .purple_t__s_pic26{
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("./img/purple_web_doc_tooth26.png");
        }
        .t__s_pic26_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth26_nonentity.png");
        }
        .t__s_pic25 {
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/web_doc_tooth25.png");
        }
        .blue_t__s_pic25{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("./img/blue_web_doc_tooth25.png");
        }
        .purple_t__s_pic25{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("./img/purple_web_doc_tooth25.png");
        }
        .t__s_pic25_nonentity{
          width: 0.31rem;
          height: 0.4rem;
          background-image: url("/static/images/teeth/25.png");
        }
        .t__s_pic24 {
          width: 0.34rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/web_doc_tooth24.png");
        }
        .blue_t__s_pic24{
          width: 0.34rem;
          height: 0.44rem;
          background-image: url("./img/blue_web_doc_tooth24.png");
        }
        .purple_t__s_pic24{
          width: 0.34rem;
          height: 0.44rem;
          background-image: url("./img/purple_web_doc_tooth24.png");
        }
        .t__s_pic24_nonentity{
          width: 0.34rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/64.png");
        }
        .t__s_pic23 {
          width: 0.42rem;
          height: 0.6rem;
          background-image: url("/static/images/teeth/web_doc_tooth23.png");
        }
        .blue_t__s_pic23{
          width: 0.42rem;
          height: 0.6rem;
          background-image: url("./img/blue_web_doc_tooth23.png");
        }
        .purple_t__s_pic23{
          width: 0.42rem;
          height: 0.6rem;
          background-image: url("./img/purple_web_doc_tooth23.png");
        }
        .t__s_pic23_nonentity{
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("/static/images/teeth/63.png");
        }
        .t__s_pic22 {
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("/static/images/teeth/web_doc_tooth22.png");
        }
        .blue_t__s_pic22{
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("./img/blue_web_doc_tooth22.png");
        }
        .purple_t__s_pic22{
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("./img/purple_web_doc_tooth22.png");
        }
        .t__s_pic22_nonentity{
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("/static/images/teeth/62.png");
        }

        .t__s_pic21 {
          width: 0.43rem;
          height: 0.56rem;
          background-image: url("/static/images/teeth/web_doc_tooth21.png");
        }
        .blue_t__s_pic21{
          width: 0.43rem;
          height: 0.56rem;
          background-image: url("./img/blue_web_doc_tooth21.png");
        }
        .purple_t__s_pic21{
          width: 0.43rem;
          height: 0.56rem;
          background-image: url("./img/purple_web_doc_tooth21.png");
        }
        .t__s_pic21_nonentity{
          width: 0.34rem;
          height: 0.46rem;
          background-image: url("/static/images/teeth/61.png");
        }

        .t__s_pic48 {
          width: 0.49rem;
          height: 0.37rem;
          background-image: url("/static/images/teeth/web_doc_tooth48.png");
        }
        .blue_t__s_pic48{
          width: 0.49rem;
          height: 0.37rem;
          background-image: url("./img/blue_web_doc_tooth48.png");
        }
        .purple_t__s_pic48{
          width: 0.49rem;
          height: 0.37rem;
          background-image: url("./img/purple_web_doc_tooth48.png");
        }
        .t__s_pic48_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth48_nonentity.png");
        }
        .t__s_pic47 {
          width: 0.5rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth47.png");
        }
        .blue_t__s_pic47{
          width: 0.49rem;
          height: 0.37rem;
          background-image: url("./img/blue_web_doc_tooth47.png");
        }
        .purple_t__s_pic47{
          width: 0.49rem;
          height: 0.37rem;
          background-image: url("./img/purple_web_doc_tooth47.png");
        }
        .t__s_pic47_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth47_nonentity.png");
        }
        .t__s_pic46 {
          width: 0.5rem;
          height: 0.43rem;
          background-image: url("/static/images/teeth/web_doc_tooth46.png");
        }
        .blue_t__s_pic46{
          width: 0.5rem;
          height: 0.43rem;
          background-image: url("./img/blue_web_doc_tooth46.png");
        }
        .purple_t__s_pic46{
          width: 0.5rem;
          height: 0.43rem;
          background-image: url("./img/purple_web_doc_tooth46.png");
        }
        .t__s_pic46_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth46_nonentity.png");
        }
        .t__s_pic45 {
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("/static/images/teeth/web_doc_tooth45.png");
        }
        .blue_t__s_pic45{
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("./img/blue_web_doc_tooth45.png");
        }
        .purple_t__s_pic45{
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("./img/purple_web_doc_tooth45.png");
        }
        .t__s_pic45_nonentity {
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("/static/images/teeth/85.png");
        }
        .t__s_pic44 {
          width: 0.37rem;
          height: 0.45rem;
          background-image: url("/static/images/teeth/web_doc_tooth44.png");
        }
        .blue_t__s_pic44{
          width: 0.37rem;
          height: 0.45rem;
          background-image: url("./img/blue_web_doc_tooth44.png");
        }
        .purple_t__s_pic44{
          width: 0.37rem;
          height: 0.45rem;
          background-image: url("./img/purple_web_doc_tooth44.png");
        }
        .t__s_pic44_nonentity {
          width: 0.37rem;
          height: 0.45rem;
          background-image: url("/static/images/teeth/84.png");
        }
        .t__s_pic43 {
          width: 0.33rem;
          height: 0.47rem;
          background-image: url("/static/images/teeth/web_doc_tooth43.png");
        }
        .blue_t__s_pic43{
          width: 0.33rem;
          height: 0.47rem;
          background-image: url("./img/blue_web_doc_tooth43.png");
        }
        .purple_t__s_pic43{
          width: 0.33rem;
          height: 0.47rem;
          background-image: url("./img/purple_web_doc_tooth43.png");
        }
        .t__s_pic43_nonentity {
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("/static/images/teeth/83.png");
        }
        .t__s_pic42 {
          width: 0.38rem;
          height: 0.43rem;
          background-image: url("/static/images/teeth/web_doc_tooth42.png");
        }
        .blue_t__s_pic42{
          width: 0.38rem;
          height: 0.43rem;
          background-image: url("./img/blue_web_doc_tooth42.png");
        }
        .purple_t__s_pic42{
          width: 0.38rem;
          height: 0.43rem;
          background-image: url("./img/purple_web_doc_tooth42.png");
        }
        .t__s_pic42_nonentity {
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("/static/images/teeth/82.png");
        }
        .t__s_pic41 {
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/web_doc_tooth41.png");
        }
        .blue_t__s_pic41{
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("./img/blue_web_doc_tooth41.png");
        }
        .purple_t__s_pic41{
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("./img/purple_web_doc_tooth41.png");
        }
        .t__s_pic41_nonentity {
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("/static/images/teeth/81.png");
        }
        .t__s_pic38 {
          width: 0.48rem;
          height: 0.37rem;
          background-image: url("/static/images/teeth/web_doc_tooth38.png");
        }
        .blue_t__s_pic38{
          width: 0.48rem;
          height: 0.37rem;
          background-image: url("./img/blue_web_doc_tooth38.png");
        }
        .purple_t__s_pic38{
          width: 0.48rem;
          height: 0.37rem;
          background-image: url("./img/purple_web_doc_tooth38.png");
        }
        .t__s_pic38_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth38_nonentity.png");
        }
        .t__s_pic37 {
          width: 0.5rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth37.png");
        }
        .blue_t__s_pic37{
          width: 0.5rem;
          height: 0.41rem;
          background-image: url("./img/blue_web_doc_tooth37.png");
        }
        .purple_t__s_pic37{
          width: 0.5rem;
          height: 0.41rem;
          background-image: url("./img/purple_web_doc_tooth37.png");
        }
        .t__s_pic37_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth37_nonentity.png");
        }
        .t__s_pic36 {
          width: 0.5rem;
          height: 0.43rem;
          background-image: url("/static/images/teeth/web_doc_tooth36.png");
        }
        .blue_t__s_pic36{
          width: 0.5rem;
          height: 0.43rem;
          background-image: url("./img/blue_web_doc_tooth36.png");
        }
        .purple_t__s_pic36{
          width: 0.5rem;
          height: 0.43rem;
          background-image: url("./img/purple_web_doc_tooth36.png");
        }
        .t__s_pic36_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/web_doc_tooth36_nonentity.png");
        }
        .t__s_pic35 {
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("/static/images/teeth/web_doc_tooth35.png");
        }
        .blue_t__s_pic35{
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("./img/blue_web_doc_tooth35.png");
        }
        .purple_t__s_pic35{
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("./img/purple_web_doc_tooth35.png");
        }
        .t__s_pic35_nonentity {
          width: 0.34rem;
          height: 0.49rem;
          background-image: url("/static/images/teeth/75.png");
        }
        .t__s_pic34 {
          width: 0.37rem;
          height: 0.45rem;
          background-image: url("/static/images/teeth/web_doc_tooth34.png");
        }
        .blue_t__s_pic34{
          width: 0.37rem;
          height: 0.45rem;
          background-image: url("./img/blue_web_doc_tooth34.png");
        }
        .purple_t__s_pic34{
          width: 0.37rem;
          height: 0.45rem;
          background-image: url("./img/purple_web_doc_tooth34.png");
        }
        .t__s_pic34_nonentity {
          width: 0.37rem;
          height: 0.45rem;
          background-image: url("/static/images/teeth/74.png");
        }
        .t__s_pic33 {
          width: 0.33rem;
          height: 0.47rem;
          background-image: url("/static/images/teeth/web_doc_tooth33.png");
        }
        .blue_t__s_pic33{
          width: 0.33rem;
          height: 0.47rem;
          background-image: url("./img/blue_web_doc_tooth33.png");
        }
        .purple_t__s_pic33{
          width: 0.33rem;
          height: 0.47rem;
          background-image: url("./img/purple_web_doc_tooth33.png");
        }
        .t__s_pic33_nonentity {
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/73.png");
        }
        .t__s_pic32 {
          width: 0.38rem;
          height: 0.43rem;
          background-image: url("/static/images/teeth/web_doc_tooth32.png");
        }
        .blue_t__s_pic32{
          width: 0.38rem;
          height: 0.43rem;
          background-image: url("./img/blue_web_doc_tooth32.png");
        }
        .purple_t__s_pic32{
          width: 0.38rem;
          height: 0.43rem;
          background-image: url("./img/purple_web_doc_tooth32.png");
        }
        .t__s_pic32_nonentity {
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/72.png");
        }
        .t__s_pic31 {
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/web_doc_tooth31.png");
        }
        .blue_t__s_pic31{
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("./img/blue_web_doc_tooth31.png");
        }
        .purple_t__s_pic31{
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("./img/purple_web_doc_tooth31.png");
        }
        .t__s_pic31_nonentity {
          width: 0.35rem;
          height: 0.44rem;
          background-image: url("/static/images/teeth/71.png");
        }

        .t__s_pic64_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/65.png");
        }
        .t__s_pic54_nonentity {
          width: 0.52rem;
          height: 0.41rem;
          background-image: url("/static/images/teeth/55.png");
        }
      }
      .t_jx_tag{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fcc80e;
        .t_jx_tag_b{
          width: 3px;
          height: 0.36rem;
          background-color: #fcc80e;
        }
        .t_jx_tag_t{
          position: relative;
          width: 1px;
          background-color:#fcc80e;
        }
      }
      .t_jx_num{
        position: absolute;
        text-align: center;
        span{
          line-height: 0.2rem;
        }
      }
    }
    .teeth___top {
      .teeth___single:last-child {
        margin-right: 0;
      }
      .t_jx_tag{
        bottom: 0.3rem;
        right: 0;
        transform: translateX(30%);
      }
      .t_jx_num{
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
      }
      .t__s_p {
        margin-top: 0.1rem;
      }
      .s_pic_box {
        justify-content: flex-end;
      }
    }
    .teeth___bottom {
      .teeth___single:last-child {
        margin-right: 0;
      }
      .t_jx_tag{
        top: 0.3rem;
        right: 0;
        transform: translateX(30%);
      }
      .t_jx_num{
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
      .t__s_p {
        margin-bottom: 0.1rem;
      }
    }
    .teeth__line_box {
      display: flex;
      align-items: center;
      padding: 0.06rem 0;
      .teeth__line {
        flex: 1;
        height: 1px;
        background-color: #B9B9B9;
        margin: 0 0.09rem;
      }
    }
    .teeth_t_strand{
      width: 65%;
      margin: 0 auto 0.1rem;
      justify-content: center;
    }
    .teeth_b_strand{
      justify-content: center;
      width: 65%;
      margin: 0.1rem auto 0;
    }
  }
}
.teeth_view_old{
  background: #F4F4F4;
}
.top_deciduous_baby{
  height: 1.09rem;
  width: 1px;
  background-color: #fcc80e;
}
.t_jx_tag_t_top{
  height: 1.3rem;
  width: 1px;
  background-color: #fcc80e;
}
.t_jx_tag_t_top_height{
  height: 2rem;
  width: 1px;
  background-color: #fcc80e;
}
.fj-tip-line-top{
  position: absolute;
  right: 50%;
  bottom: 50%;
}
.fj-tip-line-bottom{
  position: absolute;
  right: 50%;
  top: 50%;
}
.fj-tip-line{
  height: 1.5rem;
  width: 1px;
  background-color: #fcc80e;
}
.fj-tip-step{
  width: 100%;
  text-align: center;
  position: absolute;
  color: #fcc80e;
  left:50%;
  bottom: 50%;
  transform: translateX(-50%);
}
.fj-tip{
  width: 100%;
  height: 100%;
}
</style>
